.userdashboard-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    height: 60px;
    background-color: white;
}
.header-profile-container{
    display: flex;
    align-items: center;
    padding: 5px 5px;
    border-radius: 4rem;
    min-width: 10%;
    justify-content: space-between;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: white;
    box-shadow: 0px 0px 5px #ddd;
}
.header-profile-container p{
    color: black !important;
}
.arrow-container{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.1rem;
    transition: .3s all ease-in-out;
    margin-left:10px ;
    color: black;
}
.arrow-wrapper:hover{
    box-shadow: 0px 0px rgb(205, 205, 205);
    background-color: rgb(239, 239, 239);
}
.user-header-nav{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.user-header-nav ul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.user-header-nav ul li a{
    font-family: 'Lato';
    color: black;
}
.user-header-nav ul li a:hover{
    color: var(--color-variant);
}
.user-icon-wrapper{
    background-color: #ddd;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50%;
}
.user-icon-wrapper svg{
    align-self: center;
    margin: auto;
}
.header-profile-container p{
    font-size: .9rem;
    color: #ddd;
    margin-left: 10px;
    text-transform: capitalize;
    font-family: 'Lato';
    letter-spacing: .5px;
}
.drop-down{
    position: fixed;
    right: 2px;
    top: 10px;
    padding: 0px 0px 10px 0px;
    width: 280px;
    background-color: white;
    box-shadow: 0px 3px 10px #ddd;
    z-index: 10;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.dropdown-header{
    width: 100%;
    padding: 15px 20px;
    background-color: rgb(240, 240, 240);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.dropdown-user-details{
    width: 80%;
    padding: 5px;
}
.dropdown-email{
    font-size: .8rem;
    color: rgb(80, 80, 80);
    text-align: left;
    word-break: break-all;
}
.profile-pic-container{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}
.dropdown-name{
    color: var(--secondary-color);
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
}
.dropdown-deposit-container{
    width: 100%;
    padding: 10px 20px;
    font-family: roboto;
}
.dropdown-deposit-container h3{
    font-size: .9rem;
    text-align: left;
    font-family: roboto;
}
.dropdown-deposit-container h2{
    font-size: 1.5rem;
    margin: 6px 0px 6px 0px;
    text-align: left;
    font-weight: 400;
    font-family: roboto;
    color: rgba(17, 43, 209, 0.701);
}
.dropdown-deposit-container p{
    font-size: .8rem;
    text-transform: capitalize;
    color: rgb(99, 99, 99);
    text-align: left;
}
.mobile-tabs{
    width: 100%;
    display: none;
}
.dropdown-tabs{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: 0px 0px 1px #ddd;
    cursor: pointer;
}
.dropdown-tabs p{
    width: 90%;
    font-size: .8rem;
    color:rgb(99, 99, 99) ;
    text-transform: capitalize;
    text-align: left;
}
.dropdown-tabssvg{
    font-size: 1rem;
    color:rgba(17, 43, 209, 0.701);
}
.profile-pic-container img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.dropdown-tabs svg{
    margin: auto;
}
.userdashboard-logo-container{
    width: 30%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    margin-left: 65px;
}
.userdashboard-logo-container img{
    object-fit: contain;
    width: 45%;
    height: 100%;
}
.scroll-head{
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 3px 10px #ddd;
    z-index: 4;
}
@media (max-width: 990px) and (min-width: 768px){
    .user-header-nav{
        display: none;
    }
    .mobile-tabs{
        width: 100%;
        display: block;
    }
}
@media screen and (max-width: 767px){
    .user-header-nav{
        display: none;
    }
    .mobile-tabs{
        width: 100%;
        display: block;
    }
    .drop-down{
        width: 90%;
        overflow: auto;
        height: 100vh;
    }
    .userdashboard-logo-container{
        width: 60%;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
        margin-left: 0px;
    }
    .userdashboard-logo-container img{
        object-fit: contain;
        width: 60%;
        height: 100%;
    }
}